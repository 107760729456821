import { useVirtualizer } from '@tanstack/react-virtual'
import { useRef } from 'react'
import CommonListOption, { AsHeadlessType } from '../../form/common-list-option'
import { DataInterface, ExtendedDataType } from '../index'

function VirtualizedList<T extends ExtendedDataType>({
  data,
  value,
  getOptionDataAttribute,
  optionIconRenderer,
  buttonWidth,
  as,
}: {
  fullData?: T[]
  data?: T[]
  value?: DataInterface<T>['id']
  getOptionDataAttribute?: (value?: string) => void
  optionIconRenderer?: (data?: T) => JSX.Element | undefined
  buttonWidth?: number
  as: AsHeadlessType
}) {
  const parentRef = useRef<HTMLDivElement>(null)
  const rowVirtualizer = useVirtualizer({
    count: data?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
  })
  return (
    <div
      ref={parentRef}
      className={`border-collapse w-full overflow-auto rounded-md bg-white text-sm shadow-around-sm focus:outline-none customScroll`}
      style={{
        height: 240,
        width: buttonWidth,
        overflowY: 'auto',
        contain: 'strict',
      }}
    >
      <div
        style={{
          height: rowVirtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
          <CommonListOption
            item={data?.[virtualRow.index]}
            data-index={virtualRow.index}
            getOptionDataAttribute={getOptionDataAttribute}
            value={value}
            key={virtualRow.key}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
            }}
            optionRef={rowVirtualizer.measureElement}
            optionIconRenderer={optionIconRenderer}
            as={as}
          />
        ))}
      </div>
    </div>
  )
}

export default VirtualizedList

import { Combobox, Listbox } from '@headlessui/react'
import React from 'react'
import CheckIcon from 'shared/icons/check-icon'
import { twJoin } from 'tailwind-merge'
import { DataInterface, ExtendedDataType } from '../form-select/index'

export type AsHeadlessType = typeof Combobox.Option | typeof Listbox.Option

interface CommonListOptionProps<T extends ExtendedDataType> extends Record<string, unknown> {
  item: T | undefined
  getOptionDataAttribute?: (value?: string) => void
  value?: DataInterface<T>['id'] | DataInterface<T>['id'][]
  ['data-index']?: number
  optionRef?: (node: Element | null) => void
  optionIconRenderer?: (data?: T) => JSX.Element | undefined
  firstRect?: boolean
  lastRect?: boolean
  as: AsHeadlessType
}

function CommonListOption<T extends ExtendedDataType>({
  item,
  getOptionDataAttribute,
  value,
  optionRef,
  optionIconRenderer,
  firstRect,
  lastRect,
  as: As,
  ...restProps
}: CommonListOptionProps<T>) {
  const caption = item?.customCaption || item?.caption
  const isCaptionEmpty = !caption
  return (
    <As
      ref={optionRef}
      value={item}
      className={({ active }) =>
        twJoin(
          active ? 'bg-blue text-white' : 'bg-white text-darkblue',
          !firstRect && 'first:rounded-t-lg',
          !lastRect && 'last:rounded-b-lg',
          'relative px-4 py-2.5',
          'border-b last:border-b-0 border-gray/30',
          'group hover:bg-blue hover:text-white',
          'flex justify-between items-center',
        )
      }
      {...(getOptionDataAttribute ? getOptionDataAttribute(item?.caption) : {})}
      {...restProps}
    >
      {({ active }) => (
        <div
          className={twJoin(
            'flex gap-3 items-center justify-between w-full',
            isCaptionEmpty && 'text-transparent',
          )}
        >
          {isCaptionEmpty ? '.' : caption}
          <div className="flex gap-3">
            {(Array.isArray(value) ? value.includes(item?.id) : item?.id == value) && (
              <CheckIcon
                className={twJoin(
                  'h-4 w-4 group-hover:stroke-white',
                  active ? 'stroke-white' : 'stroke-blue',
                )}
                aria-hidden="true"
              />
            )}
            {optionIconRenderer && optionIconRenderer(item)}
          </div>
        </div>
      )}
    </As>
  )
}

export default CommonListOption

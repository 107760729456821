import React from 'react'
import { AsHeadlessType } from 'shared/components/form/common-list-option'
import { DataInterface, ExtendedDataType, GroupDataInterface } from '../index'
import BaseListOptions from './base-list-options'

interface BaseListProps<T extends ExtendedDataType> {
  data?: T[]
  value?: DataInterface<T>['id']
  getOptionDataAttribute?: (value?: string) => void
  groups?: GroupDataInterface[]
  optionIconRenderer?: (data?: T) => JSX.Element | undefined
  as: AsHeadlessType
}
function BaseList<T extends ExtendedDataType>({
  data,
  value,
  getOptionDataAttribute,
  groups,
  optionIconRenderer,
  as,
}: BaseListProps<T>) {
  return (
    <div
      className={`border-collapse max-h-60 w-full overflow-auto rounded-md bg-white text-sm shadow-around-sm focus:outline-none customScroll`}
    >
      {groups ? (
        groups.map(group => (
          <BaseListOptions
            data={data}
            group={group}
            value={value}
            getOptionDataAttribute={getOptionDataAttribute}
            optionIconRenderer={optionIconRenderer}
            key={group.id}
            as={as}
          />
        ))
      ) : (
        <BaseListOptions
          data={data}
          value={value}
          getOptionDataAttribute={getOptionDataAttribute}
          optionIconRenderer={optionIconRenderer}
          as={as}
        />
      )}
    </div>
  )
}

export default BaseList
